/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useState, useEffect, useMemo } from "react";
import {
  calendario,
  compras,
  dinheiro,
  linechart,
  locker,
  suporte,
  world,
  flask,
  pharmacy,
  home,
  priceTag,
  shopping2,
  batimento,
  products,
  donation
} from 'images/features'

const PageContext = createContext({color: '', setColor: () => {}});

export const PageContextProvider = ({type, children}) => {
  const [color, setColor] = useState('linear-gradient(135deg, rgba(33,98,137,1) 12%, rgba(50,150,161,1) 54%, rgba(140,208,230,1) 100%)')
  const [textColor, setTextColor] = useState('')
  const [noHoverColor, setNoHoverColor] = useState('')
  const [headerTitle, setHeaderTitle] = useState('')
  const [buttonTitle, setButtonTitle] = useState('')
  const [getStartedButton, setGetStartedButton] = useState('')
  const [headerDescription, setHeaderDescription] = useState('')
  const [benefitsSection, setBenefitsSection] = useState({})
  const [firstStepsSection, setFirstStepsSection] = useState([])
  const [secondStepsSection, setSecondStepsSection] = useState([])

  const companiesFirstSteps = useMemo(() => ([
    {
      heading: "Baixe",
      description: "Usuários são notificados sobre o novo benefício, baixam o aplicativo da Medipreço e ativam sua conta."
    },
    {
      heading: "Pesquise",
      description: "No aplicativo, o usuário tem a opção de comprar medicamentos, produtos de saúde, medicamentos manipulados e outros podendo aproveitar o subsídio oferecido pela empresa, se contratado."
    },
    {
      heading: "Escolha seus produtos",
      description: "Alternativamente, o usuário pode adquirir seus medicamentos em qualquer farmácia do Brasil via MediPay ou Reembolso através do aplicativo Medipreço."
    },
    {
      heading: '',
      description: 'Caso opte pela compra via aplicativo: O distribuidor parceiro recebe o pedido, monta a sacola e a envia para o endereço ou smart locker de preferência do usuário.'
    }
  ]), [])

  const companiesSecondSteps = useMemo(() => ([
    {
      heading: "Abra o carrinho",
      description: "O usuário recebe seu pedido e pode fazer uma avaliação de sua experiência"
    },
    {
      heading: "Selecione o tipo de entrega",
      description: "Todos os dados de compra, seja via aplicativo ou reembolso, são anonimizados e alimentam nossa plataforma de dados."
    },
    {
      heading: "Coloque seu cartão",
      description: "A inteligência de saúde da Medipreço utiliza esses dados para gerar insights diários, semanais e mensais, contribuindo para uma melhor gestão da saúde."
    },
    {
      heading: "Coloque seu cartão",
      description: "O usuário tem total controle e transparência sobre suas transações, podendo acompanhar seus pedidos, saldos e reembolsos diretamente pelo aplicativo."
    },
  ]), [])

  const collaboratorsFirstSteps = useMemo(() => ([
    {
      heading: "Baixe",
      description: "Você é notificado sobre o seu novo benefício, baixa o aplicativo da Medipreço e ativa sua conta."
    },
    {
      heading: "Pesquise",
      description: "Você acessa o nosso app e realiza a compra dos produtos desejados utilizando seu subsídio, desconto em folha, cartão de crédito, etc."
    },
    {
      heading: "Escolha seus produtos",
      description: "Recebemos o seu pedido, montamos a sua sacola e deixamos disponível para entrega. Caso tenha urgência, você pode fazer sua compra em qualquer farmácia física do Brasil"
    },
  ]), [])

  const collaboratorsSecondSteps = useMemo(() => ([
    {
      heading: "Abra o carrinho",
      description: "O entregador coleta o seu pedido e leva para o endereço ou smart locker desejado."
    },
    {
      heading: "Selecione o tipo de entrega",
      description: "Você recebe seu pedido e faz uma avaliação de sua experiência com aquela compra."
    },
    {
      heading: "Coloque seu cartão",
      description: "Durante todo o processo você é avisado sobre todos os passos do seu pedido."
    },
  ]), [])

  const partnerFirstSteps = useMemo(() => ([
    {
      heading: "Abra o carrinho",
      description: "Usuário faz uma compra através do app"
    },
    {
      heading: "Selecione o tipo de entrega",
      description: "O pedido é recebido na plataforma da Dark Pharmacy"
    },
    {
      heading: "Coloque seu cartão",
      description: "Atendente da Dark Pharmacy valida o pedido"
    },
  ]), [])

  const partnerSecondSteps = useMemo(() => ([
    {
      heading: "Abra o carrinho",
      description: "Atendente realiza picking, packing e etiquetamento do produto"
    },
    {
      heading: "Selecione o tipo de entrega",
      description: "Entregador faz a coleta e sai para a entrega"
    },
    {
      heading: "Coloque seu cartão",
      description: "Entregador chega no destino final (locker ou enderço)"
    },
    {
      heading: "Gestor mede",
      description: "Gestor mede KPIs no portal da Medipreço"
    },
  ]), [])

  const companiesBenefits = useMemo(() => ({
    heading: 'Como ajudamos',
    lineDescription: 'sua empresa',
    cards: [
      {
        imageSrc: compras,
        title: "Aplicativo completo",
        description: "Faça compras com facilidade pelo nosso app. Um aplicativo simples, intuitivo e com acesso a uma ampla variedade de medicamentos e produtos de saúde.",
        url: "contato",
        alt: "compras"
      },
      {
        imageSrc: donation,
        title: "Subsídio de medicamentos",
        description: "Use seu saldo de medicamento de forma prática: Compre pelo nosso app, utilize o MediPay em qualquer farmácia física no Brasil e/ou solicite reembolso diretamente no aplicativo.",
        url: "contato",
        alt: "subsídio"
      },
    {
        imageSrc: priceTag,
        title: "Preços exclusivos",
        description: "Além do subsídio, economize até 75% na compra de medicamentos com condições especiais.",
        url: "contato",
        alt: "tag de preco"
      },
    {
        imageSrc: dinheiro,
        title: "Parcelamento facilitado",
        description: "Pague em até 12x para adquirir o medicamento necessário mesmo sem recursos imediatos.",
        url: "contato",
        alt: "dinheiro"
      },
    {
        imageSrc: products,
        title: "Sortimento completo",
        description: "Mais de 35 mil medicamentos e produtos disponíveis para compra digital, de qualquer lugar do Brasil.",
        url: "contato",
        alt: "farmacia"
      },
      {
        imageSrc: pharmacy,
        title: "Compre em qualquer farmácia",
        description: "O colaborador tem liberdade para escolher onde comprar e pode pagar pelo nosso app ou solicitar reembolso após a compra presencial.",
        url: "contato",
        alt: "farmacia"
      },
      {
        imageSrc: calendario,
        title: "Compra agendada",
        description: "Usuários com tratamentos contínuos podem agendar seus pedidos e recebê-los automaticamente todos os meses.",
        url: "contato",
        alt: "calendario"
      },
    {
        imageSrc: flask,
        title: "Medicamentos e Produtos Manipulados",
        description: "Disponíveis no app para maior praticidade.",
        url: "contato",
        alt: "frasco"
      },
      {
        imageSrc: dinheiro,
        title: "Várias formas de pagamento",
        description: "Aceitamos cartão de crédito, PIX e até desconto em folha para atender a diferentes perfis de colaboradores.",
        url: "contato",
        alt: "dinheiro"
      },
      {
        imageSrc: locker,
        title: "Smart Lockers",
        description: "Com armários inteligentes instalados na empresa, as compras são entregues de forma prática, sem qualquer incômodo.",
        url: "contato",
        alt: "locker"
      },
      {
        imageSrc: world,
        title: "Entregas em todo Brasil",
        description: "Desde entregas rápidas em até 45 minutos até envios para todo o território nacional.",
        url: "contato",
        alt: "mundo"
      },
      {
        imageSrc: suporte,
        title: "Atendimento 100% humanizado",
        description: "Profissionais de saúde dedicados para oferecer suporte exclusivo aos seus colaboradores.",
        url: "contato",
        alt: "suporte"
      },
      {
        imageSrc: suporte,
        title: "Implantação dedicada",
        description: "Nosso time especializado de Customer Success acompanha toda a implementação, tornando o processo simples e sem esforço para sua empresa.",
        url: "contato",
        alt: "suporte"
      },
      {
        imageSrc: linechart,
        title: "Inteligência de dados em saúde",
        description: "Tenha acesso a dashboards, indicadores estratégicos, relatórios epidemiológicos e muito mais para uma gestão de saúde corporativa eficiente.",
        url: "contato",
        alt: "grafico"
      }      
    ]
  }), [])

  const collaboratorsBenefits = useMemo(() => ({
    heading: 'Vantagens da',
    lineDescription: 'Medipreço',
    cards: [
      {
        imageSrc: compras,
        title: "Aplicativo completo",
        description: "Faça compras com facilidade pelo nosso app. Um aplicativo simples, intuitivo e com acesso a uma ampla variedade de medicamentos e produtos de saúde.",
        url: "contato",
        alt: "compras"
      },
      {
        imageSrc: donation,
        title: "Subsídio de medicamentos",
        description: "Use seu saldo de medicamento de forma prática: Compre pelo nosso app, utilize o MediPay em qualquer farmácia física no Brasil e/ou solicite reembolso diretamente no aplicativo.",
        url: "contato",
        alt: "subsídio"
      },
    {
        imageSrc: priceTag,
        title: "Preços exclusivos",
        description: "Além do subsídio, economize até 75% na compra de medicamentos com condições especiais.",
        url: "contato",
        alt: "tag de preco"
      },
    {
        imageSrc: dinheiro,
        title: "Parcelamento facilitado",
        description: "Pague em até 12x para adquirir o medicamento necessário mesmo sem recursos imediatos.",
        url: "contato",
        alt: "dinheiro"
      },
    {
        imageSrc: products,
        title: "Sortimento completo",
        description: "Mais de 35 mil medicamentos e produtos disponíveis para compra digital, de qualquer lugar do Brasil.",
        url: "contato",
        alt: "farmacia"
      },
      {
        imageSrc: pharmacy,
        title: "Compre em qualquer farmácia",
        description: "O colaborador tem liberdade para escolher onde comprar e pode pagar pelo nosso app ou solicitar reembolso após a compra presencial.",
        url: "contato",
        alt: "farmacia"
      },
      {
        imageSrc: calendario,
        title: "Compra agendada",
        description: "Usuários com tratamentos contínuos podem agendar seus pedidos e recebê-los automaticamente todos os meses.",
        url: "contato",
        alt: "calendario"
      },
    {
        imageSrc: flask,
        title: "Medicamentos e Produtos Manipulados",
        description: "Disponíveis no app para maior praticidade.",
        url: "contato",
        alt: "frasco"
      },
      {
        imageSrc: dinheiro,
        title: "Várias formas de pagamento",
        description: "Aceitamos cartão de crédito, PIX e até desconto em folha para atender a diferentes perfis de colaboradores.",
        url: "contato",
        alt: "dinheiro"
      },
      {
        imageSrc: locker,
        title: "Smart Lockers",
        description: "Com armários inteligentes instalados na empresa, as compras são entregues de forma prática, sem qualquer incômodo.",
        url: "contato",
        alt: "locker"
      },
      {
        imageSrc: world,
        title: "Entregas em todo Brasil",
        description: "Desde entregas rápidas em até 45 minutos até envios para todo o território nacional.",
        url: "contato",
        alt: "mundo"
      },
      {
        imageSrc: suporte,
        title: "Atendimento 100% humanizado",
        description: "Profissionais de saúde dedicados para oferecer suporte exclusivo aos seus colaboradores.",
        url: "contato",
        alt: "suporte"
      }
    ]
  }), [])

  const partnerBenefits = useMemo(() => ({
    heading: 'Por que ser um',
    lineDescription: 'parceiro Medipreço',
    cards: [
      {
        imageSrc: shopping2,
        title: "CAC ZERO",
        description: "Custo ZERO de aquisição de cliente",
        url: "contato",
        alt: "shopping"
      },
    {
        imageSrc: linechart,
        title: "Ganhe mais dinheiro",
        description: "Aumente seu faturamento com mais pedidos",
        url: "contato",
        alt: "grafico"
      },
    {
        imageSrc: compras,
        title: "Fluxo 100% digital",
        description: "Fluxo 100% digital, atendimento feito pela Medipreço.",
        url: "contato",
        alt: "compras"
      },
    {
        imageSrc: pharmacy,
        title: "Novo canal de vendas",
        description: "Novo canal de vendas na sua operação",
        url: "contato",
        alt: "farmacia"
      },
      {
        imageSrc: dinheiro,
        title: "Redução de custos",
        description: "Reduzir custo de abertura de novas lojas e mesmo assim aumentar pedidos",
        url: "contato",
        alt: "dinheiro"
      },
    {
        imageSrc: priceTag,
        title: "Vouchers e promoções",
        description: "Incentivos para fidelizar o cliente realizados pela Medipreço",
        url: "contato",
        alt: "tag de preco"
      },
      {
        imageSrc: home,
        title: "Exclusivo",
        description: "Não conectamos concorrentes, aqui você pode ser exclusivo na sua região",
        url: "contato",
        alt: "casa"
      },
      {
        imageSrc: batimento,
        title: "Parceria verdadeira",
        description: "Relação ganha-ganha na parceria",
        url: "contato",
        alt: "batimento"
      }
    ]
  }), [])

  // function getMobileOperatingSystem() { 
  //   var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  //     // Windows Phone must come first because its UA also contains "Android"
  //   if (/windows phone/i.test(userAgent)) {
  //       return "Windows Phone";
  //   }

  //   if (/android/i.test(userAgent)) {
  //       return "Android";
  //   }

  //   // iOS detection from: http://stackoverflow.com/a/9039885/177710
  //   if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
  //       return "iOS";
  //   }

  //   return "unknown";
  // }

  // function redirecionar() {
  //   if (getMobileOperatingSystem() === "iOS") {
  //     window.location.replace("https://goo.gl/o2ThuA");
  //   } else if (getMobileOperatingSystem() === "Android") {
  //     window.location.replace("https://goo.gl/FGyBrW");
  //   } 
  // }

  function seePDF(url) {
    const userAgent = navigator.userAgent.toLowerCase()
    
    const isAndroid = userAgent.indexOf("android") !== -1
    const isiOS = userAgent.indexOf("iphone") !== -1 || userAgent.indexOf("ipad") !== -1

    if (isAndroid || isiOS) {
      window.location.href = url
    }
  }

  function redirecionar() {
    const userAgent = navigator.userAgent.toLowerCase()
    
    const isAndroid = userAgent.indexOf("android") !== -1
    const isiOS = userAgent.indexOf("iphone") !== -1 || userAgent.indexOf("ipad") !== -1

    if (isAndroid && type === 'app') {
      window.location.href = "https://goo.gl/FGyBrW"
    } else if (isiOS && type === 'app') {
      window.location.href = "https://goo.gl/o2ThuA"
    } else if (isAndroid && type === 'app-delivery') {
      window.location.href = "https://play.google.com/store/apps/details?id=br.com.medipreco.medientrega"
    } else if (isiOS && type === 'app-delivery') {
      window.location.href = "https://apps.apple.com/br/app/medientrega/id1558298850"
    }
  
  }

  useEffect(() => {
    if(type === 'companies') {
      setFirstStepsSection(companiesFirstSteps)
      setSecondStepsSection(companiesSecondSteps)
      setBenefitsSection(companiesBenefits)
      setColor('linear-gradient(89.95deg, #1481BB 0%, #69C9D3 99.92%)')
      setTextColor('#0581C0')
      setNoHoverColor('#69C9D3')
      setHeaderTitle('Planos de medicamento para seus colaboradores')
      setHeaderDescription('Com a Medipreço, eles acessam medicamentos com subsídios e descontos exclusivos, compram digitalmente ou em mais de 90 mil farmácias, e tudo isso em uma única assinatura, garantindo mais adesão ao tratamento e redução de custos para sua empresa.')
      setButtonTitle('Quero saber mais')
      setGetStartedButton('Entre em Contato')
    } else if(type === 'colaborators') {
      setFirstStepsSection(collaboratorsFirstSteps)
      setSecondStepsSection(collaboratorsSecondSteps)
      setBenefitsSection(collaboratorsBenefits)
      setColor('linear-gradient(89.95deg, #43B19B 0%, #96D8DF 99.92%)')
      setTextColor('#43B19B')
      setNoHoverColor('#96D8DF')
      setHeaderTitle('Não pague mais pelos seus medicamentos')
      setHeaderDescription('Compre seus medicamentos e diversos outros produtos de saúde com subsídios e descontos exclusivos, através do nosso app ou em mais de 90 mil farmácias. Além disso, você pode escolher entre receber seus produtos no conforto do seu lar ou retirar em um de nossos armários inteligentes.')
      setButtonTitle('Quero saber mais')
      setGetStartedButton('Quero saber mais')
    } else if(type === 'partners') {
      setFirstStepsSection(partnerFirstSteps)
      setSecondStepsSection(partnerSecondSteps)
      setBenefitsSection(partnerBenefits)
      setColor('linear-gradient(89.95deg, #FCA36A 22.9%, #C1A2FE 99.92%)')
      setTextColor('#FCA36A')
      setNoHoverColor('#C1A2FE')
      setHeaderTitle('Junte-se a nós!')
      setHeaderDescription('A Medipreço está presente em todo o Brasil e busca parceiros de qualidade para continuar crescendo. Com uma plataforma 100% digital, conectamos consumidores e parceiros de forma simples e eficiente. Vamos crescer juntos? Seja um parceiro Medipreço em sua região.')
      setButtonTitle('Por que ser parceiro?')
      setGetStartedButton('Entre em Contato')
    } else if (type ==='app' || type === 'privacy-policy' || type === 'user-agreement') {
      setColor('linear-gradient(89.95deg, #43B19B 0%, #96D8DF 99.92%)')
      setTextColor('#43B19B')
      setNoHoverColor('#96D8DF')
      setHeaderTitle('Descubra o seu novo benefício de saúde')
      setHeaderDescription('Agora você pode comprar medicamentos e diversos outros produtos de saúde pelo aplicativo da Medipreço, com preços incríveis e opções de parcelamento em até 12x. Além disso, você pode escolher entre receber seus produtos no conforto do seu lar ou retirar em um de nossos armários inteligentes.')
      setButtonTitle('Baixe o App')
      setGetStartedButton('Baixe o App')
      redirecionar()
    } else if (type === 'app-delivery') {
      setColor('linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%)')
      setTextColor('#4158D0')
      setNoHoverColor('#FFCC70')
      setHeaderTitle('É muito fácil entregar com a Medipreço. Faça parte agora!')
      setHeaderDescription('Baixe o aplicativo e comece a entregar agora na sua região!')
      setButtonTitle('Baixe o App')
      setGetStartedButton('Baixe o App')
      redirecionar()
    }
  },[type])

  return (
    <PageContext.Provider value={{
      color,
      textColor,
      noHoverColor,
      headerTitle,
      buttonTitle,
      getStartedButton,
      headerDescription,
      benefitsSection,
      firstStepsSection,
      secondStepsSection,
      redirecionar,
      seePDF,
      type
      }}
    >
      {children}
    </PageContext.Provider>
  )
}

export default PageContext 

