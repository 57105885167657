import React, { useContext } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import ImageApp from 'images/app.png';
import MobileStoreButton from 'react-mobile-store-button';

// import Header, { NavLink, NavLinks, LogoLink, NavToggle, DesktopNavLinks } from "../headers/light.js";
import ResponsiveVideoEmbed from "helpers/ResponsiveVideoEmbed.js";
import { PrimaryLink } from "components/buttons/index.js";
import PageContext from 'hooks/pageContext'
import { useScrollOfHeaderButton } from 'helpers/ScrollToTop'
import { Link } from "react-router-dom";
// const StyledHeader = styled(Header)`
//   ${tw`pt-8 max-w-none`}
//   ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
//     ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
//   }
//   ${NavToggle}.closed {
//     ${tw`text-gray-100 hover:text-primary-500`}
//   }
// `;
const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover`}
  background: ${({toggle}) => toggle };
`;

const HeroContainer = tw.div`z-20 relative px-4 sm:px-8 max-w-screen-xl mx-auto`;
const TwoColumn = styled.div`
 ${tw`pt-24 pb-40 sm:pb-32 px-4 flex justify-between items-center flex-col lg:flex-row`}
 ${({type}) => type === 'companies' && tw`sm:pb-16`}
 `;
const LeftColumn = tw.div`flex flex-col items-start max-w-lg`;
const RightColumn = tw.div`w-full sm:w-5/6 lg:w-1/2 mt-16 lg:mt-0 pb-16`;

const Heading = styled.h1`
  ${tw`text-4xl text-left sm:text-5xl lg:text-6xl font-bold text-gray-100 leading-none`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const SpaceButton = tw.div`flex flex-col sm:flex-row items-center self-start`
const SpaceLink = styled.div`
  ${tw`flex flex-col self-start`}
`
const DeliveryLink = styled(Link)`
  ${tw`
    self-start text-white text-lg mt-6 lg:text-lg
    font-semibold transition duration-300 hover:opacity-50
    cursor-pointer
  `};
`
const AppLink = styled.a`
  ${tw`self-start text-white text-lg mt-6 lg:text-lg
  font-semibold transition duration-300 hover:opacity-50
  cursor-pointer`}
  ${({type}) => type === 'app-delivery' && tw`hidden`}
`
const Text = tw.span`border-b-2 border-white`

const IosButton = {
  marginTop: 12,
}

const Span = tw.span`leading-snug sm:leading-tight text-left `

const Notification = tw.span`inline-block text-left text-gray-100 font-medium text-xl text-left lg:text-left font-thin my-4`;

// const PrimaryAction = tw.button`px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 bg-gray-100 text-primary-500 font-bold rounded shadow transition duration-300 hocus:bg-primary-500 hocus:text-gray-100 focus:shadow-outline`;

const StyledResponsiveVideoEmbed = styled(ResponsiveVideoEmbed)`
  padding-bottom: 56.25% !important;
  padding-top: 0px !important;
  margin-bottom: 70px;
  ${tw`rounded`}
  iframe {
    ${tw`rounded bg-black shadow-xl`}
  }
`;

export default () => {
  const {color, headerTitle, headerDescription, buttonTitle, type} = useContext(PageContext)
  const ScrollOfHeaderButton = useScrollOfHeaderButton('beneficios')

  let androidURL = "https://goo.gl/FGyBrW"
  let iosURL = "https://goo.gl/o2ThuA"
  let textApp = 'Conheça também o nosso App para entregadores'
  let navigation = "/app-delivery"
  if (type === 'app-delivery') {
    textApp = 'Baixe nosso App principal'
    navigation = "/app"
    androidURL = "https://play.google.com/store/apps/details?id=br.com.medipreco.medientrega"
    iosURL = "https://apps.apple.com/br/app/medientrega/id1558298850"
  }

  return (
      <Container toggle={color}>
        {/* <OpacityOverlay /> */}
        <HeroContainer>
          <TwoColumn type={type}>
            <LeftColumn>
              <Heading>
                <Span>{headerTitle}</Span>
                <br />
                {/* <SlantedBackground>Marketing Team.</SlantedBackground> */}
              </Heading>
              <Notification>{headerDescription}</Notification>
              {type === 'app' || type === 'app-delivery' ? (
                <>
                  <SpaceButton>
                    <MobileStoreButton 
                    store="ios"
                    url={iosURL}
                    width={152}
                    linkStyles={IosButton}
                    linkProps={{ title: 'Baixar Medipreço na AppStore' }}
                    />
                    <MobileStoreButton 
                    store="android"
                    width={200}
                    url={androidURL}
                    linkProps={{ title: 'Baixar Medipreço na Play Store' }}
                    />
                  </SpaceButton>
                  <SpaceLink>
                    <AppLink href="https://app.medipreco.com.br/" type={type}>
                      <Text>Conheça nossa versão Web do aplicativo</Text>
                    </AppLink>
                    <DeliveryLink to={navigation}>
                      <Text>{textApp}</Text>
                    </DeliveryLink>
                  </SpaceLink>
                </>
              ) : (
                <PrimaryLink onClick={ScrollOfHeaderButton} >{buttonTitle}</PrimaryLink>
              )}
            </LeftColumn>
            <RightColumn>
              <StyledResponsiveVideoEmbed
                url={ImageApp}
                background="transparent"
              />
            </RightColumn>
          </TwoColumn>
        </HeroContainer>
      </Container>
  );
};
