import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import LogoImage from "images/logo-medipreco-white-horizontal.png";
import { SocialIcon } from "react-social-icons";
// import { ReactComponent as FacebookIcon } from "images/facebook-icon.svg";
// import { ReactComponent as InstagramIcon } from "images/instagram-icon.svg";
// import { ReactComponent as LinkedinIcon } from "images/linkedin-icon.svg";
// import { ReactComponent as YoutubeIcon } from "images/youtube-icon.svg";
import { DecorationBlob } from 'components/misc/DecorationBlob'
import PageContext from 'hooks/pageContext';
// import { handlePoliticPage, handleTermsPage } from "helpers/ScrollToTop";

const Container = styled.div`
  ${tw`relative bg-primary-500 text-gray-100 -mb-8 -mx-8 px-8 py-20 lg:py-24`};
  background: ${({toggle}) => toggle}
`
const Content = tw.div`max-w-screen-xl mx-auto relative z-10`;
const FooterColumns = tw.div`flex flex-col text-center sm:text-center md:text-start justify-start sm:justify-start md:justify-end mt-4`;

const Column = tw.ul`px-4 sm:px-0 sm:w-1/3 md:w-auto md:mx-6 mt-8 text-start`;
const ColumnContainer = tw.div`flex flex-col md:flex-row md:justify-between`
const Section = tw.div``

const ColumnHeading = tw.h5`text-start uppercase font-bold text-2xl`;

// const LinkList = tw.ul`mt-6 text-sm font-medium`;
const AddressItem = tw.li`mt-3 list-none text-start text-sm`;
const ColumnItemHeading = tw.li`mt-3 mb-2 list-none text-start font-bold text-sm mt-6`;
const ColumnItem = tw.a`text-start mb-8 cursor-pointer border-b-2 border-transparent hocus:border-gray-100 pb-1 transition duration-300`;
const Link = tw.a`border-b-2 border-transparent hocus:border-gray-100 pb-1 transition duration-300 cursor-pointer font-bold`;

const Divider = tw.div`my-16 border-b-2 border-white w-full`;

const ThreeColRow = tw.div`flex flex-col md:flex-row items-center justify-center mt-8`;
const MediInfoContainer = tw.div`flex flex-col items-center justify-center`;

const LogoContainer = tw.div`flex items-center md:mt-10 justify-start`;
const LogoImg = tw.img`h-16 md:h-24`;

const PhraseContainer = tw.div`flex flex-col items-center justify-center mt-6 mb-8 text-center`
const Phrase = tw.p``

// const EmailContainer = tw.div`flex flex-col items-center justify-center mt-6 mb-6 md:justify-start`;
// const Email = tw.a`border-b-2 border-transparent hocus:border-gray-100 pb-1 transition duration-300 mb-3`;

const CopywrightNotice = tw.p`text-center text-sm sm:text-base font-medium text-white`;

const SocialLinksContainer = tw.div`mt-12 md:mt-0 flex items-center justify-center md:hidden`;
const SocialLinksContainer2 = tw.div` hidden flex items-center justify-center md:flex md:justify-center md:mt-40`;
const SocialLink = styled.a`
  ${tw`cursor-pointer p-2 rounded-full bg-gray-100 text-gray-900 hover:bg-gray-400 transition duration-300 mr-4 last:mr-0`}
  svg {
    ${tw`w-10 h-10 md:w-16 md:h-16`}
  }
`;

const SocialStyle = { height: 80, width: 80 }

const DecoratorBlobContainer = tw.div`absolute inset-0 overflow-hidden rounded-lg`;
const DecoratorBlob1 = tw(
  DecorationBlob
)`absolute top-0 left-0 w-80 h-80 transform -translate-x-20 -translate-y-32 text-primary-700 opacity-50`;
const DecoratorBlob2 = tw(
  DecorationBlob
)`absolute bottom-0 right-0 w-80 h-80 transform  translate-x-32 translate-y-48 text-primary-700 opacity-50`;

export default () => {
  const { textColor } = useContext(PageContext)
  const navigate = useNavigate()

  const handlePoliticPage = () => {
    navigate("/privacy-policy")
  }

  const handleTermsPage = () => {
    navigate("/user-agreement")
  }

  const social_networks = [
    {
      link: 'https://www.facebook.com/usemedipreco/',
      Logo: 'facebook',
    },
    {
      link: 'https://www.linkedin.com/company/medipreco',
      Logo: 'linkedin',
    },
    {
      link: 'https://www.instagram.com/medipreco/',
      Logo: 'instagram',
    },
    {
      link: 'https://www.youtube.com/channel/UCPO49T5k7n1D9dlksdP2psw',
      Logo: 'youtube',
    },
  ]

  return (
    <Container toggle={textColor}>
      <Content>
        <ColumnContainer>
          <Section>
            <LogoContainer>
              <LogoImg src={LogoImage} alt="Logo Medipreco"/>
            </LogoContainer>
            <SocialLinksContainer2>
                {social_networks.map(({Logo, link}) => (
                  <SocialLink key={Logo} target="_blank" href={link}>
                    <SocialIcon style={SocialStyle} bgColor={textColor} network={Logo} />
                  </SocialLink>
                ))}
            </SocialLinksContainer2>
          </Section>
          <Section>
            <FooterColumns>
              <Column>
                <ColumnHeading>Endereço</ColumnHeading>
                <AddressItem>Setor SGCV (St Garagens e Conces de Veículos) Lote, 12/22</AddressItem>
                <AddressItem>Complemento: Ae Shopping Casa Park, Andar 1 Sala 3 e 4</AddressItem>
                <AddressItem>CEP: 71215-100</AddressItem>
                <AddressItem>Brasília: Zona Industrial-Guará</AddressItem>
              </Column>
            </FooterColumns>
            <FooterColumns>
              <Column>
                <ColumnHeading>Contatos</ColumnHeading>

                <ColumnItemHeading>Quero contratar a Medipreço:</ColumnItemHeading>
                <ColumnItem href="mailto:comercial@medipreco.com.br">comercial@medipreco.com.br</ColumnItem>
                
                <ColumnItemHeading>Quero ser parceiro Medipreço:</ColumnItemHeading>
                <ColumnItem href="mailto:parcerias@medipreco.com.br">parcerias@medipreco.com.br</ColumnItem>

                <ColumnItemHeading>Tenho dúvidas:</ColumnItemHeading>
                <ColumnItem href="mailto:contato@medipreco.com.br">contato@medipreco.com.br</ColumnItem>

              </Column>
            </FooterColumns>
            <FooterColumns>
              <Column>
                <Link onClick={handlePoliticPage}>Política de Privacidade</Link>
              </Column>
              <Column>
                <Link onClick={handleTermsPage}>Termos de Uso</Link>
              </Column>
            </FooterColumns>
            <SocialLinksContainer>
                {social_networks.map(({Logo, link}) => (
                  <SocialLink key={Logo} target="_blank" href={link}>
                    <SocialIcon bgColor={textColor} network={Logo} />
                  </SocialLink>
                ))}
            </SocialLinksContainer>
          </Section>
        </ColumnContainer>
        <Divider />
        <PhraseContainer>
          <Phrase>A MEDIPREÇO não realiza o comércio, distribuição, transporte, armazenamento ou dispensação de medicamentos, sendo responsável apenas pela intermediação tecnológica, via aplicativo, para aproximar fornecedores de consumidores.</Phrase>
        </PhraseContainer>
        <MediInfoContainer>
          <CopywrightNotice>MEDIPREÇO SERVIÇOS SA</CopywrightNotice>
          <CopywrightNotice>CNPJ: 32.493.343/0001-21</CopywrightNotice>
        </MediInfoContainer>
        <ThreeColRow>
          <CopywrightNotice>&copy; {new Date().getFullYear()} Medipreço. Todos os direitos reservados.</CopywrightNotice>
        </ThreeColRow>
      </Content>
      <DecoratorBlobContainer>
        <DecoratorBlob1 />
        <DecoratorBlob2 />
      </DecoratorBlobContainer>
    </Container>
  );
};
