import { createGlobalStyle } from 'styled-components'
import  { globalStyles } from 'twin.macro'

const GlobalStyles = createGlobalStyle(globalStyles, `
   /* Below animations are for modal created using React-Modal */
     .ReactModal__Overlay {
     transition: transform 300ms ease-in-out;
     transition-delay: 100ms;
     transform: scale(0);
   }
   .ReactModal__Overlay--after-open{
     transform: scale(1);
   }
   .ReactModal__Overlay--before-close{
     transform: scale(0);
   }

   * {
    font-family: 'NaN Holo Condensed', sans-serif;
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
   }

  body::-webkit-scrollbar {
    width: 12px;       
  }
  
  body::-webkit-scrollbar-track {
    background:  #FFF;
  }
  
  body::-webkit-scrollbar-thumb {
    background-color: #808080;
    border-radius: 20px;
    border: 2px solid  #FFF;
  }

`)

export default GlobalStyles