import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";
import AONlogo from 'images/clients/resized_aon-logo.png'
import JCBlogo from 'images/clients/resized_jcb-logo.png'
import Leroylogo from 'images/clients/resized_logo-leroy-merlin.png'
import Recordlogo from 'images/clients/resized_logo-record.png'
import SAMIlogo from 'images/clients/resized_logo-sami.png'
import Santanderlogo from 'images/clients/resized_logo-santander-red.png'
import VRlogo from 'images/clients/resized_logo-vr.png'
import Vivestlogo from 'images/clients/resized_log-vivest.png'
import Marshlogo from 'images/clients/resized_marsh-logo.png'
import Cemiglogo from 'images/clients/resized_cemig-logo.png'
import Hyperalogo from 'images/clients/resized_hypera-logo.png'
import Cajulogo from 'images/clients/resized_caju-logo.png'
import Zuplogo from 'images/clients/resized_zup-logo.png'
import Cliquelogo from 'images/clients/resized_clique-logo.png'
import Danonelogo from 'images/clients/resized_danone-logo.png'
import Duracelllogo from 'images/clients/resized_duracell-logo.png'
import ESPNlogo from 'images/clients/resized_espn-logo.png'
import Disneylogo from 'images/clients/resized_disney-logo.png'
import Volkslogo from 'images/clients/resized_volks-logo.png'
import Arcelorlogo from 'images/clients/resized_arcelor-logo.png'
import "slick-carousel/slick/slick.css";
import LineDescription from "components/texts/LineDescription.js";
import Carrousel from "components/carrousel/index.js";
import { DecorationBlob } from "components/misc/DecorationBlob.js";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
const HeadingInfoContainer = tw.div`flex flex-col items-start`;

const CompaniesSliderContainer = tw.div`mt-16`;

const ImageContainer = styled.div`
  ${tw`!flex flex-1 items-center justify-center md:mx-3 lg:mx-6 w-2/3 h-24 md:w-4/12 mx-auto rounded `}
`

const ImageSpan = tw.div`p-16 flex items-center justify-center`

const Image = tw.img`w-4/5 max-w-xs`

const DecoratorBlob1 = styled(DecorationBlob)`
  ${tw`pointer-events-none z-20 overflow-hidden absolute left-0 bottom-0 h-56 w-56 opacity-15 transform -translate-x-2/3 -translate-y-12 text-teal-400`}
`;
// const DecoratorBlob2 = styled(DecorationBlob)`
//   ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-15 transform translate-x-2/3 text-yellow-500`}
// `;

const CarrouselList = (props) => {
  /*
   * You can modify the companies shown by modifying the array below
   * You can add or remove objects from the array as you need.
   */
  const companies = [
    {
      imageSrc: Disneylogo,
      alt: 'Disney'
    },
    {
      imageSrc: Danonelogo,
      alt: 'Danone'
    },
    {
      imageSrc: ESPNlogo,
      alt: 'ESPN'
    },
    {
      imageSrc: Santanderlogo,
      alt: 'Santander'
    },
    {
      imageSrc: AONlogo,
      alt: 'AON'
    },
    {
      imageSrc: Leroylogo,
      alt: 'Leroy Merlin'
    },
    {
      imageSrc: Vivestlogo,
      alt: 'Vivest'
    },
    {
      imageSrc: Cemiglogo,
      alt: 'Cemig'
    },
    {
      imageSrc: SAMIlogo,
      alt: 'Sami'
    },
    {
      imageSrc : Hyperalogo,
      alt: 'Hypera Pharma'
    },
    {
      imageSrc: Cajulogo,
      alt: 'Caju'
    },
    {
      imageSrc: Zuplogo,
      alt: 'Zup'
    },
    {
      imageSrc: Marshlogo,
      alt: 'Marsh'
    },
    {
      imageSrc: Cliquelogo,
      alt: 'Clique Retire'
    },
    {
       imageSrc: JCBlogo,
       alt: 'JCB'
    },
    {
      imageSrc: Recordlogo,
      alt: 'Record TV'
    },
    {
      imageSrc: VRlogo,
      alt: 'VR'
    },
    {
      imageSrc: Volkslogo,
      alt: 'Volkswagen Caminhões e Ônibus'
    },
    {
      imageSrc: Arcelorlogo,
      alt: 'ArcelorMittal'
    },
    {
      imageSrc: Duracelllogo,
      alt: 'Duracell'
    },
  ];

  return (
      <Container id="clientes"> 
        <Content>
          <HeadingInfoContainer>
            <HeadingTitle>{props.title}</HeadingTitle>
            <LineDescription>{props.subTitle}</LineDescription>
          </HeadingInfoContainer>
          <CompaniesSliderContainer>
            <Carrousel>
              {companies.map((company, index) => (
                <ImageContainer key={company.alt}>
                  <ImageSpan key={company.alt}>
                    <Image src={company.imageSrc} alt={company.alt}/>
                  </ImageSpan>
                </ImageContainer>
              ))}
            </Carrousel>
          </CompaniesSliderContainer>
        </Content>

        <DecoratorBlob1 />
        {/* <DecoratorBlob2 /> */}
      </Container>
  );
};

export default CarrouselList;
