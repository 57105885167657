import React, { useContext } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import RoudedGraph from "components/grapths/RoundedGraph";
import SectionTitle from "components/texts/SectionTitle";
import { GrayContainer } from "components/misc/Layouts";
import { PrimaryLink } from "components/buttons";
import { useScrollOfHeaderButton } from "helpers/ScrollToTop";
import PageContext from "hooks/pageContext";

const TwoColumn = tw.div`flex flex-col-reverse md:flex-row justify-between max-w-screen-xl mx-auto py-5 md:py-5`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-6/12 lg:w-5/12 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-6/12 mt-8 md:mt-0`,
  props.textOnLeft ? tw`md:mr-8 lg:mr-16 md:order-first` : tw`md:ml-8 lg:ml-16 md:order-last`
]);

const TextContent = tw.div`lg:py-8`;

const Description = tw.p`text-left md:text-left text-lg md:text-lg lg:text-lg font-medium leading-relaxed text-secondary-100 mt-4`

const Statistics = tw.div`flex flex-wrap flex-col items-center justify-center sm:flex-row`
const Statistic = tw.div`text-lg sm:text-2xl lg:text-3xl w-1/2 mt-4 lg:mt-10 text-center items-center justify-center flex flex-col`
const Value = tw.div`w-56 sm:w-40`
const Key = tw.div`font-medium text-gray-700 text-xl w-72 sm:text-lg sm:w-56 md:w-80 md:px-16 lg:w-64 lg:px-8 xl:w-64 xl:px-0 font-thin text-center py-4`
const Button1 = tw(PrimaryLink)`hidden md:inline`
const Button2 = tw(PrimaryLink)`md:hidden block`

export default ({textOnLeft = false}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
  //Change the statistics variable as you like, add or delete objects
  const ScrollOfHeaderButton = useScrollOfHeaderButton('contato')
  const {buttonTitle} = useContext(PageContext)
  const statistics = [
    {
      key: "dos trabalhadores brasileiros consideram benefícios de saúde como os mais relevantes",
      value: 73,
    },
    {
      key: "de retorno: para cada R$ 1 investido, é possível economizar até R$ 4",
      value: 300,
    },
    {
      key: `das faltas no trabalho no Brasil estão relacionadas a problemas de saúde`,
      value: 36,
    },
    {
      key: "dos pacientes não seguem o tratamento conforme prescrito no Brasil",
      value: 53,
    }
  ]

  return (
    <GrayContainer id="porque-priorizar-saude">
      <TwoColumn>
        <ImageColumn>
          <Statistics>
            {statistics.map((statistic, index) => (
              <Statistic key={index}>
                <Value>
                  <RoudedGraph value={statistic.value} />
                </Value>
                <Key>{statistic.key}</Key>
              </Statistic>
            ))}
          </Statistics>
          <Button2 onClick={ScrollOfHeaderButton} >{buttonTitle}</Button2>
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <SectionTitle heading="Por que priorizar" lineDescription="saúde?" />
            <Description>
              Investir em saúde é investir em <b>produtividade, bem-estar</b> e na <b>sustentabilidade do seu negócio</b>.
            </Description>
            <Description>
              Cuidar dos colaboradores vai além do benefício: é uma estratégia que gera resultados reais. A adesão a <b>tratamentos preventivos</b> pode reduzir em até <b>30% os custos com internações e atendimentos de emergência</b>.
            </Description>
            <Description>
              Empresas com <b>bons benefícios de saúde</b> registram até <b>60% menos rotatividade</b> de colaboradores. Com nossa plataforma, sua empresa oferece um benefício acessível e eficiente, promovendo saúde, reduzindo custos e aumentando a satisfação e a produtividade da equipe.
            </Description>
            <Description>
              Quer saber mais? Fale com a gente! :)
            </Description>
          </TextContent>
          <Button1 onClick={ScrollOfHeaderButton} >{buttonTitle}</Button1>
        </TextColumn>
      </TwoColumn>
    </GrayContainer>
  );
};
